import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { StaticImage } from "gatsby-plugin-image";

import { Breadcrumb, Dropdown, Button } from "../components";
import { dummy } from "../components/common-images";
import { groupBy } from "../utils/utils";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const PublicationItems = props => {
  const { data: publicationData } = props;

  const [data, setData] = useState({
    parsedData: [],
    dropdownData: [],
    dropdownDefault: "",
  });
  // const onChange = e => {
  //   setData({ ...data, dropdownDefault: e.target.value });
  // };

  useEffect(() => {
    if (publicationData) {
      const groupedData = groupBy(publicationData?.publication_volumes, "year");
      const groupedKeys = Object?.keys(groupedData);
      const defaultKey = groupedKeys[groupedKeys?.length - 1];

      setData({
        parsedData: groupedData,
        dropdownData: groupedKeys.map(option => {
          return { Id: option, Name: option };
        }),
        dropdownDefault: defaultKey,
      });
    }
  }, [publicationData]);

  if (publicationData) {
    return (
      <div className={["col-12"].join(" ")}>
        <div className="pub-items">
          <div className="pub-items__top">
            <div className="pub-items__image">
              <LazyLoadImage
                width={180}
                height={267}
                src={
                  publicationData.image_url ? publicationData.image_url : dummy
                }
                alt={publicationData.title}
              />
            </div>
            <div className="pub-items__content">
              <h5>{publicationData?.title}</h5>
              <ReactMarkdown
                children={publicationData?.full_description}
                remarkPlugins={[remarkGfm]}
              />
            </div>
          </div>
          <div className="pub-items__bottom">
            <div className="pub-items__bottom-container">
              {/* <h5 className="pub-items__bottom-head">
                Volumes - &nbsp;
                <Dropdown
                  data={data?.dropdownData}
                  selected={data?.dropdownDefault}
                  onChange={onChange}
                />
              </h5> */}
              {data?.parsedData[data?.dropdownDefault]?.map(pb => {
                return (
                  <div className="pub-items__card" key={pb.uuid}>
                    <StaticImage
                      width={58}
                      height={58}
                      src="../images/pdf-img.png"
                      alt="pdf icon"
                    />
                    <span className="pub-items__card-title">
                      {publicationData?.title} - {pb.edition}
                    </span>
                    <a
                      href={pb.asset_full_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none"
                    >
                      <Button
                        title="Read Now"
                        theme="primary"
                        type="button"
                        variant="solid"
                      />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="col-12">
      <p className="p-2">There are no publications available</p>
    </div>
  );
};

const PublicationTypes = props => {
  // eslint-disable-next-line no-unused-vars
  const { data, selected, yearList } = props;
  const onChange = e => {
    navigate(`/publication-detail/${e.target.value}`);
  };

  if (data) {
    return (
      <>
        <div className="row">
          <div className="col-12 py-4">
            <Dropdown data={yearList} selected={selected} onChange={onChange} />
          </div>
        </div>
        <div className="row">
          <PublicationItems data={data} isPage={true} />
        </div>
      </>
    );
  }
  return null;
};

const breadcrumbData = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 2,
    name: "Publications",
  },
];

const Publications = props => {
  return (
    <div className="about pb-0">
      <Breadcrumb data={breadcrumbData} />
      <h3 className="about-subtitle mb-0">Publications</h3>
      <PublicationTypes
        selected={props.selected}
        data={props.data}
        yearList={props.yearList}
      />
    </div>
  );
};

export default Publications;
