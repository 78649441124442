import React from "react";

import Layout from "../components/layout";
import { Seo } from "../components";
import Publications from "../container/publications";

const PublicationsPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Publication Detail" />
      <Publications />
    </Layout>
  );
};

export default PublicationsPage;
